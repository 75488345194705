<template>
  <section>
    <div
      class="first"
      :style="{ width: '100%', height: innerHeight + 100 + 'px', top: '-100px', minWidth: innerWidth + 'px' }"
    >
      <rive-canvas src="home.riv" :width="innerWidth" :height="innerHeight" class="first-canvas" />
      <div class="first-content">
        <div class="first-title animate__animated animate__fadeInDown">
          <img src="@/assets/first_ellipse.svg" alt="" />
          <span>exSat Mainnet Launched on 2024/10/23 </span>
        </div>
        <h1 class="animate__animated animate__fadeInUp">
          Extending Satoshi's Vision
          <br />
          Unlocking Bitcoin's Possibilities
        </h1>
        <div class="animate__animated animate__fadeInUp">
          <a href="https://exsat.network/app" class="btn btn-white exsat-app-link" target="_blank">
            BTC Metadata Sync Protocol
            <icon value="external" />
          </a>
        </div>
      </div>
      <div class="first-bottom">
        <img src="@/assets/Frame.png" alt="" class="first-bottom-bg-1" />
        <img src="@/assets/Frame-bg.png" alt="" class="first-bottom-bg-2" />
        <div class="first-bottom-text">
          <!-- <div>
            <div class="exSat-tvl">exSat TVL</div>
            <div class="exSat-tvl-value">100,000,000</div>
          </div> -->
          <div class="exSat-bottom-item">
            <div class="exSat-bottom-item-left">
              <div class="exSat-bottom-item-left-title">Connected BitCoin Hashrate</div>
              <div class="exSat-bottom-item-left-value">
                {{ exSatData.connectedBtcHashrate ? exSatData.connectedBtcHashrate : '-' }}
              </div>
            </div>
            <div class="exSat-bottom-item-right">
              <div class="exSat-bottom-item-right-title">Total Validators</div>
              <div class="exSat-bottom-item-right-value">
                {{ exSatData.totalValidators ? formatNumber(exSatData.totalValidators, 0) : '-' }}
              </div>
            </div>
          </div>
          <div class="exSat-bottom-item">
            <div class="exSat-bottom-item-left">
              <div class="exSat-bottom-item-left-title">Total BTC Staked</div>
              <div class="exSat-bottom-item-left-value">
                {{
                  !exSatData.totalBtcStaked
                    ? '-'
                    : formatNumber(exSatData.totalBtcStaked, 1) + ' BTC'
                }}
              </div>
            </div>
            <div class="exSat-bottom-item-right">
              <div class="exSat-bottom-item-right-title">Total Synchronizers</div>
              <div class="exSat-bottom-item-right-value">
                {{
                  exSatData.totalSynchronizers ? formatNumber(exSatData.totalSynchronizers, 0) : '-'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { fetchApi } from '@/utils.js'

import RiveCanvas from '@/components/RiveCanvas.vue'
const innerWidth = ref(0)
const innerHeight = ref(0)
const handleResize = () => {
  innerWidth.value = window.innerWidth
  innerHeight.value = window.innerHeight
  if (window.innerWidth < 1280) {
    innerWidth.value = 1280
  }
  if (window.innerHeight < 1080) {
    innerHeight.value = 1080
  }

  // if (window.innerWidth === 1440) {
  //   innerWidth.value = 1440 / 0.75;
  // }
}

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
  getTableData()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

const exSatData = ref({})

async function getTableData() {
  const res = await fetchApi('https://api.exsat.network/v1/misc/statistic')
  exSatData.value = res
}

function formatNumber(num, decimals) {
  return Number(num)
    .toFixed(decimals)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
</script>

<style lang="scss" scoped>
.first-title {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  height: 34px;
  line-height: 100%;
  letter-spacing: 0.14px;
  text-transform: capitalize;
  border-radius: 999px;
  border: 1px solid #3a3a3a;
  background: linear-gradient(180deg, #1e1e1e 0%, #141414 100%);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-lg, 12px);
  width: 364px;
  margin: 0 auto;
  text-align: left;
  padding: 0px var(--spacing-2xl, 20px);
  overflow: hidden;
}

.exsat-app-link {
  text-decoration: none;
  margin-right: 10px;
}

@media (min-width: 1440px) and (max-width: 1440px) {
  .first {
    zoom: 0.75;
  }
}

.first {
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  h1 {
    margin-bottom: 24px;
    font-size: 48px;
    letter-spacing: -1%;
    background: linear-gradient(115deg, #fff 31.25%, #999 115%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    pointer-events: none !important;
  }

  @media (min-width: 1280px) and (max-width: 1400px) {
    h1 {
      font-size: 64px;
    }
  }

  @media (min-width: 1400px) {
    h1 {
      font-size: 72px;
    }
  }

  // @media (min-width: 1536px) {
  //   h1 {
  //     font-size: 72px;
  //   }
  // }
}

.first-canvas {
  position: absolute;
  top: 0;
  left: 0;
  scale: 1.2;
}

.first-content {
  margin-top: 100px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  box-sizing: border-box;
  pointer-events: none;
}

.first-content h1,
.first-content a {
  pointer-events: auto;
}

.first-bottom {
  position: absolute;
  bottom: 0;
  width: 100vw;
  min-width: 1920px !important;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  pointer-events: none;

  .first-bottom-bg-1 {
    position: absolute;
    width: 1920px !important;
    min-width: 1920px !important;
    bottom: 0;
    // left: 0;
    z-index: 1;
    pointer-events: none;
  }

  .first-bottom-bg-2 {
    width: 100vw;
    min-width: 1920px !important;
    position: absolute;
    bottom: -20px;
    left: 0;
    z-index: 2;
    opacity: 0.88;
  }
}

.first-bottom-text {
  position: static;
  z-index: 4;
  width: 620px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0;
  margin-left: -140px;
  margin-bottom: 80px;
  pointer-events: none;
}

.exSat-tvl {
  text-align: center;
  margin-top: 20px;
  color: var(--colors-text-text-secondary-700, #bfbfbf);
  text-shadow: 0px 0px 4px #b9b9b9;
  font-family: 'Chakra Petch';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
  letter-spacing: 0.2px;
}

.exSat-tvl-value {
  color: #ffa926;
  text-align: center;
  text-shadow:
    0px 7px 4px #3c2b11,
    0px 0px 7px #f90;
  font-family: 'Chakra Petch';
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 108px */
}

.exSat-bottom-item {
  display: flex;
  justify-content: space-between;
}

.exSat-bottom-item-left {
  width: 50%;
}

.exSat-bottom-item-right {
  width: 50%;
}

.exSat-bottom-item-left-title,
.exSat-bottom-item-right-title {
  color: var(--colors-text-text-secondary-700, #bfbfbf);
  text-align: center;
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
  font-family: 'Chakra Petch';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
  letter-spacing: 0.2px;
}

.exSat-bottom-item-left-value,
.exSat-bottom-item-right-value {
  color: var(--colors-foreground-fg-brand-primary-600, #ffb029);
  text-align: center;
  text-shadow: 0px 0px 7px #f90;
  font-family: 'Chakra Petch';
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 87px */
}
</style>
