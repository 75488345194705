<template>
  <nav class="header" :class="[navClass, { 'menu-active': menuShown }]">
    <div class="header-container">
      <div class="header-logo header-col">
        <a href="/" target="_top">
          <img :src="getSvg('logo')" />
        </a>
      </div>
      <div class="right-buttons header-col exsat-app-link">
        <a href="/app/bridge" class="btn btn-white" target="_blank">
          Bridge
          <icon value="external" />
        </a>
        <div class="menu-btn" @click="menuShown = !menuShown">
          <img src="@exsat/app/src/assets/img/close.svg" alt="" v-if="menuShown" />
          <img src="@exsat/app/src/assets/img/menu.svg" alt="" v-else />
        </div>
      </div>
    </div>
  </nav>

  <transition name="slide-left">
    <div class="mobile-nav" v-if="menuShown">
      <div class="nav-group" v-for="menu in menuList" :key="menu.title">
        <div class="nav-group-title">{{ menu.title }}</div>
        <div class="nav-group-list">
          <component
            class="nav-item"
            :is="navItem.to ? 'router-link' : 'a'"
            v-bind="getBind(navItem)"
            v-for="navItem in menu.items"
            :key="navItem.title"
          >
            <div class="icon-wrapper"><img class="icon" :src="getSvg2(navItem.icon)" alt="" /></div>
            <div>
              <div class="title">{{ navItem.title }}</div>
              <div class="desc">{{ navItem.context }}</div>
            </div>
            <div class="badge badge-green ml-auto" v-if="navItem.badge">{{ navItem.badge }}</div>
          </component>
        </div>
        <div class="menu-links" v-if="menu.links">
          <a
            :href="i.href"
            target="_blank"
            class="menu-link"
            v-for="i in menu.links"
            :key="i.title"
          >
            <icon class="link-icon" :value="i.icon" />
            {{ i.title }}
            <icon value="chevron-right" />
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, watch, onBeforeUnmount } from 'vue'
import { getSvg2 } from '@/utils.js'

const menuList = [
  // {
  //   title: 'Bridge',
  //   icon: 'external-white',
  //   href: '/app/bridge'
  // },
  {
    title: 'Ecosystem',
    items: [
      {
        title: 'BTC Metadata Sync Protocol',
        context: "Extends BTC's data consensus in real-time",
        href: '/app',
        icon: 'zap'
      },
      {
        title: 'Stake to Get Reward',
        context: 'Get XSAT reward with ease',
        href: import.meta.env.VITE_STAKEING_URL,
        icon: 'layers'
      },
      {
        title: 'Explorer',
        context: 'Block explorer and analytics platform',
        href: 'https://scan.exsat.network',
        icon: 'compass'
      }
    ]
  },
  {
    title: 'Build',
    items: [
      {
        title: 'Become a Synchronizer',
        context: 'Sync BTC Metadata, earn up to 50% rewards',
        href: 'https://docs.exsat.network/synchronizer-mining-pool',
        icon: 'signal'
      },
      {
        title: 'Become a Validator',
        context: 'Validate BTC Metadata, earn up to 90% rewards',
        href: 'https://docs.exsat.network/validator',
        icon: 'passcode'
      },
      {
        title: 'Deploy a DApp',
        context: 'Secure, seamless Bitcoin integrationn',
        href: 'https://docs.exsat.network/developer-guides/developer-guides',
        icon: 'coins'
      }
    ],
    links: [
      {
        title: 'Docs',
        icon: 'docs',
        href: 'https://docs.exsat.network'
      },
      {
        title: 'Github',
        icon: 'github',
        href: 'https://github.com/exsat-network'
      },
      {
        title: 'Faucet',
        icon: 'faucet',
        href: 'https://faucet.exsat.network'
      }
    ]
  },
  {
    title: 'Community',
    items: [
      {
        title: 'Donate',
        context: 'Support the development of exSat network',
        href: '/app/donate',
        icon: 'currency'
      },
      {
        title: 'Medium',
        context: 'Stay updated by following our blog',
        href: 'https://medium.com/exsat-network',
        icon: 'check'
      }
    ],
    links: [
      {
        title: 'Twitter',
        href: 'https://x.com/exsatnetwork',
        icon: 'twitter'
      },
      {
        title: 'Telegram',
        href: 'https://t.me/ExSatNetwork',
        icon: 'telegram'
      },
      {
        title: 'Discord',
        href: 'https://discord.gg/KrUJbemKwk',
        icon: 'discord'
      }
    ]
  }
]

const menuShown = ref(false)

watch(menuShown, (newVal) => {
  if (newVal) {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
})

onBeforeUnmount(() => {
  document.body.style.overflow = ''
})

const { navClass } = defineProps({
  navClass: [String, Array]
})

function getBind(item) {
  const bind = {}
  if (item.to) {
    bind.to = item.to
  }
  if (item.href) {
    bind.href = item.href
    bind.target = item.target || '_blank'
  }
  return bind
}
</script>

<style lang="scss" scoped>
@function px2rem($px) {
  @return ($px / 16) + rem;
}

.btn {
  text-decoration: none;
  padding: var(--spacing-md) var(--spacing-xl);
}

.header {
  z-index: 20;
  padding: px2rem(10) px2rem(16);
  transition: 0.3s;

  @include sm {
    font-size: var(--font-md);
  }
}

.header-container {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.header-col {
  flex: 1;
  padding-top: px2rem(5);
  padding-bottom: px2rem(5);
}

.right-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-btn {
  margin-left: px2rem(10);

  img {
    width: px2rem(24);
    height: px2rem(24);
  }
}

.mobile-nav {
  position: fixed;
  top: px2rem(60);
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 999;
  padding: px2rem(32);
  overflow-y: auto;
  font-weight: 600;
  height: 100vh;
  padding-bottom: px2rem(300);

  .link-icon {
    width: px2rem(16);
    height: px2rem(16);
  }

  .nav-group-title {
    color: var(--text-gray);
    font-size: px2rem(14);
    margin-bottom: px2rem(14);
  }

  .nav-group {
    padding-bottom: px2rem(18);

    + .nav-group {
      padding-top: px2rem(18);
      border-top: 1px solid #1f1f1f;
    }
  }

  .nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--text-white);
    padding: var(--spacing-md) 0;
    text-decoration: none;

    .icon-wrapper {
      width: px2rem(40);
      height: px2rem(40);
      padding: px2rem(8);
      text-align: center;
      line-height: px2rem(36);
      display: flex;
      align-items: center;
      gap: px2rem(12);
      align-self: stretch;
      border-radius: px2rem(8);
      border: 1px solid #1f1f1f;
      background: #1f1f1f;
      margin-right: px2rem(10);
    }

    .icon {
      width: px2rem(40);
      height: px2rem(40);
    }

    .desc {
      color: var(--text-gray);
      font-size: px2rem(14);
      font-weight: 400;
    }
  }
}

:deep(.button-content) {
  padding: px2rem(12) px2rem(16);
}

.logo {
  height: px2rem(30);
  min-width: px2rem(88);
  display: block;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: 0.3s;
}

.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.fade-down-enter-active,
.fade-down-leave-active {
  transition: 0.3s;
}

.fade-down-enter-from,
.fade-down-leave-to {
  opacity: 0;
  transform: translateY(-10%);
}

.bg-dark {
  background-color: #000;
  z-index: 999;
}

.bg-transparent {
  background-color: transparent !important;
}

.menu-links {
  display: flex;
  flex-direction: row;
  padding: px2rem(10);
  gap: px2rem(10);

  .menu-link {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 100%;
    padding: px2rem(10) px2rem(16);
    border-radius: px2rem(8);
    border: 1px solid var(--border-gray);
    text-decoration: none;
    font-size: px2rem(14);
    font-style: normal;
    font-weight: 600;
  }
}
.exsat-app-link {
  text-decoration: none;
  min-width: 96px;
}
</style>
